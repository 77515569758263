<template>
  <el-dialog
    :visible.sync="visible"
    :width="width"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="payment-not-support"
  >
    <div class="content">
      <div>
        <img class="logo" src="../../../assets/images/unknow.png" />
      </div>
      <div class="title">{{ $t('message.checkout.unavailable') }}</div>
      <div class="message">{{ $t('message.checkout.notSupportPaymentTips') }}</div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button class="button" type="primary" @click="closeDialog" v-if="returnUrl">
          {{ $t('message.checkout.returnText') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    returnUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      width: '0px',
    };
  },
  created() {
    if (window.innerWidth > 600) {
      this.width = '600px';
    } else {
      this.width = `${window.innerWidth - 60}px`;
    }
  },
  methods: {
    closeDialog() {
      if (this.returnUrl) {
        this.$emit('update:visible', false);
        window.location.href = this.returnUrl;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep(.payment-not-support) {
  border-radius: 25px;
}
.content {
  padding: 0 20px;
  .logo {
    width: 134px;
  }
  .title {
    font-size: 24px;
    color: #212B36;
    line-height: 29px;
    margin-top: 40px;
  }
  .message {
    font-weight: 400;
    font-size: 18px;
    color: #616D7A;
    line-height: 22px;
    margin-top: 10px;
    word-break: initial;
  }
}
.dialog-footer {
  padding: 0 20px 20px;
  .button {
    width: 100%;
  }
}
@media screen and(max-width: 768px){
  .content {
    padding: 0;
  }
  .dialog-footer {
    padding: 0 0 25px;
  }
}
</style>
