import { addLog } from '@/api/common';

import { applePayValidateMerchant } from '../../api/aggregate';

export const APPLE_PAY_LANG = {
  de: 'de-DE',
  en: 'en-GB',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  nl: 'nl-NL',
  no: 'nb-NO',
  pl: 'pl-PL',
  pt: 'pt-PT',
  fi: 'fi-FI',
  sv: 'sv-SE',
  ru: 'ru-RU',
  ar: 'ar-AB',
  th: 'th-TH',
  'zh-cn': 'zh-CN',
  ja: 'ja-JP',
  'zh-tw': 'zh-TW',
  ko: 'ko-KR',
};
export const ApplePay = {
  initButton: async (merchantIdentifier, lang, merchantNo, externalOrderId) => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (window.ApplePaySession) {
      // 在Apple Pay按钮显示之前判断是否支持Apple Pay
      // 中国特殊验证
      // if (lang === 'zh-cn') {
      //   // 检查设备
      //   const isIOS = /ipad|iphone/.test(userAgent) && !window.MSStream;
      //   // ipad userAgent可能没有ipad字样，需要特殊处理
      //   const isNewIpad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      //   if (!isIOS && !isNewIpad) {
      //     addLog({
      //       merchantNo,
      //       externalOrderId,
      //       message: 'Apple Pay device not support:' + userAgent,
      //     });
      //     return;
      //   }
      //   // 检查ios版本
      //   const iosVersionMatch = userAgent.match(/os (\d+)_(\d+)_?(\d+)?/);
      //   const ipadVersionMatch = userAgent.match(/version\/(\d+).(\d+).?(\d+)?/);
      //   if (!((iosVersionMatch && iosVersionMatch[2]) || (ipadVersionMatch && ipadVersionMatch[2]))) {
      //     addLog({
      //       merchantNo,
      //       externalOrderId,
      //       message: 'Apple Pay device not support:' + userAgent,
      //     });
      //     return;
      //   }
      //   const deviceVersion = iosVersionMatch && Number(iosVersionMatch[1] + '.' + iosVersionMatch[2]);
      //   const deviceVersion2 = ipadVersionMatch && Number(ipadVersionMatch[1] + '.' + ipadVersionMatch[2]);
      //   if (!((deviceVersion && deviceVersion >= 11.2) || (deviceVersion2 && deviceVersion2 >= 11.2))) {
      //     addLog({
      //       merchantNo,
      //       externalOrderId,
      //       message: 'Apple Pay device not support:' + userAgent,
      //     });
      //     return false;
      //   }
      // };
      // 通用验证
      try {
        var canMakePayments = await window.ApplePaySession.canMakePayments();
        if (canMakePayments) {
          return true;
        }
        addLog({
          merchantNo,
          externalOrderId,
          message: 'Apple Pay device not support(canMakePayments false):' + userAgent,
        });
        return false;
      } catch (error) {
        addLog({
          merchantNo,
          externalOrderId,
          message: 'Apple Pay canMakePayments Error',
        });
        return false;
      }
      // var promise = await window.ApplePaySession.canMakePaymentsWithActiveCard(ApplePay.merchantIdentifier);
      // promise.then((canMakePayments) => {
      //   if (canMakePayments) {
      //     // Display Apple Pay button here.
      //     console.log('canMakePayments');
      //   }
      //   console.log('canMakePayments', canMakePayments);
      // }).catch((err) => {
      //   console.log('error', err, typeof err, JSON.stringify(err));
      // });
    } else {
      addLog({
        merchantNo,
        externalOrderId,
        message: 'Apple Pay device not support(no ApplePaySession) :' + userAgent,
      });
      return false;
    }
  },
  startPay: (request, txnKey, merchantNo, externalOrderId, callback, closeLoading) => {
    if (window.ApplePaySession) {
      // // 不支持Apple Pay
      // 使用前已做 initButton 校验, 没必要再校验一次
      // if (!window.ApplePaySession.canMakePayments()) {
      //   addLog({
      //     merchantNo,
      //     externalOrderId,
      //     message: 'Apple Pay device not support',
      //   });
      //   if (closeLoading) {
      //     closeLoading();
      //   }
      //   return;
      // }
      // 点击Apple Pay按钮后才创建Apple Pay Session
      // version, paymentRequest
      var session = new window.ApplePaySession(1, request);
      // 服务器验证Session
      session.onvalidatemerchant = event => {
        // Call your own server to request a new merchant session.
        const params = {
          key: txnKey,
          website: window.location.hostname,
          instCode: 'ApplePay',
          verifyUrl: event.validationURL,
        };
        applePayValidateMerchant(params)
          .then(res => {
            if (res.respCode === '20000') {
              const mSession = JSON.parse(res.data);
              session.completeMerchantValidation(mSession);
            } else if (closeLoading) {
              session.completeMerchantValidation({});
              closeLoading();
            }
          })
          .catch(err => {
            session.completeMerchantValidation({});
            console.error('Error fetching merchant session', err);
            if (closeLoading) {
              closeLoading();
            }
          });
      };
      // Payment Method Selected
      // session.onpaymentmethodselected = event => { // 处理时间: 30 second
      //   console.log('onpaymentmethodselected===========', event);
      //   const myPaymentMethod = event.paymentMethod;
      //   const ckeckoutUpdate = {
      //     newTotal: {
      //       label: 'Subscription', // TODO
      //       amount: 0.5,
      //       type: 'final',
      //     },
      //   };
      //   session.completePaymentMethodSelection(ckeckoutUpdate);
      // };
      // Payment Authorized
      session.onpaymentauthorized = event => {
        const applePaymentToken = event.payment.token; // ApplePay支付Token
        if (callback) {
          callback(applePaymentToken, session);
        } else if (closeLoading) {
          closeLoading();
        }
        // 请求后端返回后调用
        // session.completePayment(session.STATUS_SUCCESS); // session.STATUS_SUCCESS session.STATUS_FAILURE
      };
      // 取消支付
      session.oncancel = event => {
        if (closeLoading) {
          closeLoading();
        }
        addLog({
          merchantNo,
          externalOrderId,
          message: 'Apple Pay canceled',
        });
        console.log('apple pay canceled');
      };
      session.begin();
    } else {
      console.log('Apple Pay is not available');
      if (closeLoading) {
        closeLoading();
      }
    }
  },
};
