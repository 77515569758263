/*
 * @Author:
 * @Description:
 * @Date: 2024-10-08 15:37:03
 * @LastEditors:
 * @FilePath: /rhcn-checkout-web/src/api/aggregate.js
 */
import http from "@/utils/http";

export function getOrderInfo(data) {
  data.transactionWebsite =
    window && window.document && window.document.referrer;
  return http.post("/txn/local/checkoutDetail", data);
}

export function payment(data) {
  return http.post("/txn/local/confirmPayment", data);
}

export function getPaymentInfo(key) {
  return http.get(`/txn/checkoutBasicInfo?key=${key}`);
}

/**
 * Apple Pay验证
 * @param {*} data
 * @returns
 */
export function applePayValidateMerchant(data) {
  return http.post("/txn/checkApplePay", data);
}
/**
 * Google Pay验证
 * @param {*} data
 * @returns
 */
export function googlePayValidateMerchant(data) {
  return http.post("/txn/checkGooglePay", data);
}
