<template>
  <div v-if="open" class="validate-cvv-wrapper">
    <div class="validate-cvv">
      <div class="validate-cvv-title">
        <p class="title">{{ $t('message.checkout.validateCVVTitle') }}</p>
        <p class="title-desc">{{ $t('message.checkout.validateCVVDesc') }}</p>
      </div>
      <div class="validate-cvv-content">
        <el-form>
          <el-form-item :label="$t('message.checkout.cardNumber')">
            <el-input disabled v-model="form.cardNum"></el-input>
          </el-form-item>
          <el-form-item :label="$t('message.checkout.cvv')">
            <el-input class="cvv" v-model="cvv" maxlength="4" autofocus @input="onCVVChange">
              <template slot="suffix">
                <div class="img-cvv-box">
                  <el-popover placement="bottom-end" trigger="hover" :visible-arrow="false" :offset="5"
                    popper-class="cvv-popover">
                    <img slot="reference" class="img-cvv" src="@/assets/images/icon-cvv.png" />
                    <div>
                      <div class="cvv-content-top">
                        <img src="@/assets/images/icon-cvv1.png" />
                        <img src="@/assets/images/icon-cvv2.png" />
                      </div>
                      <div class="cvv-content-bottom">{{ $t('message.checkout.cvvTips') }}</div>
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" class="btn-done" :disabled="cvv.length < 3"
          @click="onDone">{{ $t('message.checkout.continue') }}</el-button>
      </div>
      <div class="btn-close" @click="onCancel"><i class="el-icon-close"></i></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['app']),
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      cvv: '',
    };
  },
  methods: {
    onCVVChange(value) {
      this.cvv = value.replace(/[^\d]/g, '');
    },
    onCancel() {
      this.$emit('cancel');
    },
    onDone() {
      this.$emit('done', { ...this.form, cvv: this.cvv });
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/element-variables.scss';

.validate-cvv-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .validate-cvv {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    padding-top: 47px;
    padding-left: 33px;
    padding-right: 34px;
    padding-bottom: 48px;
    border-radius: 10px;
    width: 517px;
    box-sizing: border-box;

    .validate-cvv-title {
      width: 100%;

      .title {
        font-weight: 600;
        font-size: 28px;
        color: $color-text-primary;
        line-height: 32px;
        margin: 0;
        text-align: center;
        margin-bottom: 17px;
      }

      .title-desc {
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        color: $color-text-primary;
        line-height: 18px;
        margin: 0;
        margin-bottom: 24px;
      }
    }

    .validate-cvv-content {
      text-align: left;
      width: 100%;

      .el-form .el-form-item {
        margin-bottom: 4px;

        .el-form-item__label {
          font-weight: 600;
          font-size: 14px;
          color: $color-text-primary;
          line-height: 32px;
        }

        .el-input.cvv {
          &.el-input--suffix {
            .el-input__inner {
              padding-right: 60px;
            }
          }
        }

        .el-input.is-disabled .el-input__inner {
          background-color: #F4F4F4;
        }

        .el-input__inner {
          height: 44px;
          line-height: 44px;
        }

        .img-cvv {
          display: block;
          cursor: pointer;
          width: 34px;
          height: 21px;
        }
      }

      .content {
        font-weight: 400;
        font-size: 16px;
        color: $color-text;
        line-height: 20px;
        margin-bottom: 28px;
        text-align: left;
      }

      .btn-done {
        width: 100%;
        border-radius: 2px;
        margin-top: 46px;
      }
    }

    .btn-close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 20px;
      height: 20px;
      background: #F1F4F8;
      border-radius: 4px;
      color: $color-text;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1140px) {
  .validate-cvv-wrapper {
    .validate-cvv {
      width: 344px;
      max-width: 344px;
      // flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 42px;
      padding-bottom: 52px;

      .validate-cvv-title {
        .title {
          font-size: 24px;
          margin-bottom: 16px;
        }

        .title-desc {
          margin-bottom: 26px;
        }
      }

      .validate-cvv-content {
        .el-form .el-form-item {
          margin-bottom: 8px;
        }

        .btn-done {
          margin-top: 23px;
        }
      }
    }
  }
}
</style>
